import * as React from 'react';
import {Suspense, useCallback, useEffect, useState} from 'react';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {Avatar, Box, Container, Divider, Grid, Paper, Skeleton, Stack, Typography} from "@mui/material";
import axios from "axios";
import Loading from "../Loading";
import PersianDate from "../PersianDate/PersianDate";
import InfiniteScroll from "react-infinite-scroll-component";

interface IdeaInterface {
  id: string;
  title: string,
  thumbnail: string
  published_at: string;
  singlePath: string;
  slug: string;
  link: string;
  avatar: string;
  views: string;
  comments: string;
  custom_tag: string;
  author_name: string;
  category: string;
  category_slug: string;
  category_icon: {
      id:string
      url:string
  }
}

interface IdeasListInterface {
  url: string;
  title: string;
  singlePath: string,
  icon: any,
}

function Header(props: { title: string, icon: any }) {
  return (
    <Grid container className={'posts-title'}>
      <Grid item>
        <Typography sx={{my: 1}}>
          {props.icon}
          <Box component='span'
               sx={{verticalAlign: 'top'}}>{props.title}</Box>
        </Typography>
      </Grid>
    </Grid>
  );
}



function PostRowSkeleton() {
    return (
        <Paper className={'post-paper'} elevation={0} sx={{ position: 'relative', p: 1, textAlign: 'center' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ position: 'relative' }}>
                    <Box
                        sx={{
                            width: '100%',
                            height: '200px',
                            objectFit: 'cover',
                            borderRadius: '8px',
                        }}
                    >
                        <Skeleton variant="rectangular" width="100%" height="100%" style={{borderRadius: '6px'}} />
                    </Box>

                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: '8px',
                            left: 0,
                            right: 0,
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Skeleton variant="rectangular" width="80px" height="24px" style={{marginRight:8,borderRadius: '6px'}} />
                        <Skeleton variant="rectangular" width="60px" height="24px"  style={{marginLeft:26,borderRadius: '6px' }} />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='h6'>
                        <Skeleton width="80%" />
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: 2,
                        }}
                    >
                        <Box>
                            <Stack direction="row" alignItems="center">
                                <Skeleton variant="circular" width={24} height={24} />
                                <Skeleton variant="text" width="60px" sx={{ mx: 1 }} />
                                <Skeleton variant="text" width="40px" />
                            </Stack>
                        </Box>

                        <Box>
                            <Skeleton variant="text" width="50px" />
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Divider
                sx={{
                    mt: 2,
                    borderColor: 'rgba(117,117,117,0.32)',
                    borderWidth: 0.4,
                }}
            />
        </Paper>
    );
}




function PostRow(props: { post: IdeaInterface, singlePath: string }) {

  return (
      <Paper className={'post-paper'} elevation={0} sx={{position: 'relative', p: 1, textAlign: 'center'}}>
              <Grid container spacing={2}>
                  <Grid item xs={12} sx={{position: 'relative'}}>
                      <a className='no-underline' href={props.post.link}>
                          <Box
                              component='img'
                              src={'https://ramzarz.news/' + props.post.thumbnail}
                              sx={{
                                  width: '100%',
                                  height: 'auto',
                                  objectFit: 'cover',
                                  borderRadius: '8px',
                                  border: theme => `${
                                      theme.palette.mode === 'dark'
                                          ? '1px solid rgba(255, 255, 255, 0.4)'
                                          : '1px solid rgba(1, 0, 0, 0.1)' 
                                  }`,
                                  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',

                              }}
                          />
                      </a>
                          <Box
                              sx={{
                                  position: 'absolute',
                                  bottom: '8px',
                                  left: 0,
                                  right: 0,
                                  display: 'flex',
                                  justifyContent: 'space-between',

                              }}
                          >
                             <a  className='no-underline' href={'https://ramzarz.news/ideas/cat/' + props.post.category_slug}>
                                 <Box
                                     sx={{
                                         display: 'flex',
                                         alignItems: 'center',
                                         backgroundColor: '#ffcc34',
                                         color: '#fff',
                                         borderRadius: '8px',
                                         padding: '2px 5px',
                                         marginBottom:1,
                                         marginRight:1,
                                     }}
                                 >
                                     <Box
                                         component="img"
                                         src={props.post?.category_icon?.url}
                                         // alt={props.post?.category}
                                         sx={{
                                             width: 16,
                                             height: 16,
                                             borderRadius: '50%',
                                             ml: 0.8,
                                         }}
                                     />
                                     <Typography variant="caption" fontSize={'11px'}    color="black">
                                         {props.post?.category}
                                     </Typography>
                                 </Box>
                             </a>
                              <Box
                                  sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      borderRadius: '8px',
                                      padding: '0px 6px',
                                      marginBottom: 1,
                                      marginLeft: 3,
                                      backgroundColor: props.post.custom_tag === "1" ? '#00a683' :
                                          props.post.custom_tag === "2" ? '#f13d3d' :
                                              '#8f8f8f',
                                      color: '#8f8f8f'
                                  }}
                              >
                                  <Typography variant="caption" fontSize={'0.85rem'} fontWeight={700} color="white">
                                      <Box
                                          sx={{
                                              display: 'flex',
                                              alignItems: 'center',

                                          }}
                                      >
                                          {props.post.custom_tag === "1" ? (
                                              <>
                                                  <span style={{marginRight: '0.25rem', fontSize: 11}}>صعودی</span>
                                                  <svg xmlns="http://www.w3.org/2000/svg" fill={"#ffffff"}
                                                       style={{
                                                           marginRight: '0.1rem',
                                                           marginLeft: '0.3rem',
                                                           width: '0.5rem',
                                                           height: '0.6rem'
                                                       }}
                                                       viewBox="0 0 384 512">
                                                      <path
                                                          d="M32 448c-17.7 0-32 14.3-32 32s14.3 32 32 32l96 0c53 0 96-43 96-96l0-306.7 73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3 160 416c0 17.7-14.3 32-32 32l-96 0z"/>
                                                  </svg>
                                              </>
                                          ) : props.post.custom_tag === "2" ? (
                                              <>
                                                  <span style={{marginRight: '0.25rem', fontSize: 11}}>نزولی</span>
                                                  <svg xmlns="http://www.w3.org/2000/svg"  fill={"#ffffff"}
                                                       viewBox="0 0 384 512"
                                                       style={{
                                                           marginRight: '0',
                                                           marginLeft: '0.4rem',
                                                           width: '0.7rem',
                                                           height: '0.6rem'
                                                       }}
                                                  >
                                                      <path
                                                          d="M32 64C14.3 64 0 49.7 0 32S14.3 0 32 0l96 0c53 0 96 43 96 96l0 306.7 73.4-73.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-128 128c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 402.7 160 96c0-17.7-14.3-32-32-32L32 64z"/>
                                                  </svg>

                                              </>
                                          ) : (
                                              <>
                                                  <span style={{fontSize: 11}}>خنثی  </span>
                                                  <svg xmlns="http://www.w3.org/2000/svg"  fill={"#ffffff"}
                                                       style={{
                                                           marginRight: '0',
                                                           marginLeft: '0.4rem',
                                                           width: '0.7rem',
                                                           height: '0.6rem'
                                                       }}
                                                       viewBox="0 0 448 512">
                                                      <path
                                                          d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/>
                                                  </svg>
                                              </>
                                          )}
                                      </Box>
                                  </Typography>
                              </Box>
                          </Box>
                  </Grid>

                  <Grid item xs={12}>
                      <a className='no-underline' href={props.post.link}>
                          <Typography variant='h6' fontWeight='750' color='text.primary' textAlign="start">
                              {props.post.title}
                          </Typography>
                      </a>
                  </Grid>

                  <Grid item xs={12}>
                      <Box
                          sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginTop:2
                          }}
                      >
                          <Box >
                              <Stack direction="row" alignItems="center">
                                  <Avatar alt={props.post.author_name} src={props.post.avatar} sx={{ width: 24, height: 24, border: '1px solid blue',  }} />

                                  <Typography fontSize={'14px'} fontWeight={700} color='text.secondary' sx={{ mr: 1,ml:1 }} noWrap>
                                      {props.post.author_name}
                                  </Typography>

                                  <Typography fontSize={'10px'} color='text.secondary' sx={{ mr: 1 }}>
                                      <PersianDate date={props.post.published_at}  />
                                  </Typography>
                              </Stack>
                          </Box>

                          <Box>
                              <Typography className={"iransans-fa-num"} fontSize={'0.8rem'} color='text.secondary' sx={{ display: 'flex', alignItems: 'center' }}>
                                  {/*<ChatBubbleIcon sx={{ fontSize: '0.8rem', ml: 0.5 }} />*/}
                                  {/*{props.post.comments}*/}
                                  <RemoveRedEyeIcon sx={{ fontSize: '0.9rem', ml: 0.5, mr: 2 }} />
                                  {props.post.views}
                              </Typography>
                          </Box>
                      </Box>
                  </Grid>
              </Grid>

          <Divider
              sx={{
                  mt: 2,
                  borderColor: 'rgba(117,117,117,0.32)',
                  borderWidth: 0.4
              }}
          />
      </Paper>
);
}

function Posts(props: {
    posts: IdeaInterface[], singlePath: string }) {
  return (
    <Box>
      {props.posts.map((post, index) => (
        <PostRow key={index} post={post} singlePath={props.singlePath}/>
      ))}
    </Box>
  );
}



const fetchPosts = async (url: string, page: number): Promise<IdeaInterface[]> => {
    const response = await axios.get(`${url}&page=${page}`);
    return response.data;
};

function SuspendedPosts({ url, singlePath }: { url: string; singlePath: string }) {
    const [posts, setPosts] = useState<IdeaInterface[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [finished, setFinished] = useState<boolean>(false);

    useEffect(() => {
        getPosts();
    }, []);

    const getPosts = useCallback(() => {
        setLoading(true);
        const page = Math.floor(posts.length / 20) + 1;
        fetchPosts(url, page)
            .then((data) => {
                setPosts(posts.concat(data));
                setFinished(data.length < 20);
            })
            .catch((err) => {
                setFinished(true);
                if (err.response && err.response.status === 400 && err.response.data.code === 'rest_post_invalid_page_number') {
                    setFinished(true);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [posts, url]);

    return (
        <InfiniteScroll
            dataLength={posts.length}
            next={getPosts}
            hasMore={!finished}
            loader={<Loading />}
            endMessage={
                <p style={{ textAlign: 'center' }}>
                    <b>اطلاعات بیشتر برای نمایش وجود ندارد</b>
                </p>
            }
            style={{ overflow: 'unset' }}
        >
            {loading && posts.length === 0 ? (
                Array.from(new Array(5)).map((_, index) => <PostRowSkeleton key={index} />)
            ) : (
                <Posts posts={posts} singlePath={singlePath} />
            )}
        </InfiniteScroll>
    );
}

export default function PostsList(props: IdeasListInterface) {
    return (
        <Container disableGutters maxWidth={false} sx={{ px: 1 }}>
            <Header title={props.title} icon={props.icon} />
            <Divider />
            <Suspense fallback={<Loading />}>
                <SuspendedPosts url={props.url} singlePath={props.singlePath} />
            </Suspense>
        </Container>
    );
}